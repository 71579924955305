import { useEffect, useRef, useState } from "react";
import * as Auth from '@aws-amplify/auth';
import { BQProgress } from "./BQUI";

const AdditionalResource = (props) => {
    const {
        name,
        url,
        params
    } = props
    const iframeRef = useRef(null);
    const [isContentReady, setContentReady] = useState(false)

    const urlToUse = url

    useEffect(() => {
        if (iframeRef.current && isContentReady) {
            Auth.fetchAuthSession().then((session) => {
                const payload = {
                    accessToken: session?.tokens?.accessToken?.toString(),
                    idToken: session?.tokens?.idToken?.toString(),
                }
                console.log(`Posting credentials to the '${name}' iframe`)
                iframeRef.current.contentWindow.postMessage({
                    type: 'bqAuthentication',
                    payload
                }, urlToUse);
            }).catch((error) => {
                console.error(`Could not post credentials to the '${name}' iframe`, error)
            });

        }
    }, [isContentReady]);

    return <><iframe src={urlToUse}
        allow="clipboard-write; clipboard-read"
        ref={iframeRef}
        className="additionResource"
        onLoad={() => setContentReady(true)}
        {...(params || {})}
    />
        {!isContentReady && <>Page is loading, please wait...<br /><br /><BQProgress /></>}
    </>
}

export default AdditionalResource